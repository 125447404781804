<template>
    <div>
        <rx-navBar :title="dayTitle"></rx-navBar>
        <div class="part">
            
            <div  class="part-inputpart">
                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-row" v-if="dataType == '2'">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">可调休天数</span>
                    <span class="content-divide">|</span>
                    <span class="partInputpartRowNormaltext">{{overDays}}</span>
                    <!-- <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                        src="../../../assets/images/timepick.png"> -->
                </div>

                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-row" @click="isDaysShow = true">
                    <span :class="dataForm.expectedTime_id?'content-have':'content-none'"></span>
                    <span class="part-inputpart-row-header" v-if="dataType == '1'">加班日期</span>
                    <span class="part-inputpart-row-header" v-if="dataType == '2'">调休日期</span>
                    <span class="content-divide">|</span>
                    <span :class="dataForm.expectedTime_id?'partInputpartRowNormaltext':'part-inputpart-row-graytext'">{{dataForm.expectedTime_name?dataForm.expectedTime_name:'请选择'}}</span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                        src="../../../assets/images/timepick.png">
                </div>

                <!-- 加班 -->
                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-textarea" v-if="dataType == '1'">
                    <div class="part-inputpart-row">
                        <div :class="num > 0 ? 'content-have':'content-none'"></div>
                        <div class="part-inputpart-row-header">工作内容 {{num}}/300</div>
                    </div>
                    <textarea v-model="dataForm.remark" placeholder="工作内容300字内" @input="updateCount(dataForm.remark)"
                        :class="true ? 'textarea-readonly' : ''"></textarea>
                </div>

                <!-- 调休 -->
                <div style="margin-top: 15px;border-radius: 8px" class="part-inputpart-textarea" v-if="dataType == '2'">
                    <span class="part-inputpart-row-enablenon part-inputpart-row-header">工作内容 {{num}}/300</span>
                    <textarea v-model="dataForm.remark" placeholder="工作内容300字内" @input="updateCount(dataForm.remark)"
                        :class="true ? 'textarea-readonly' : ''"></textarea>
                </div>
                
                
            </div>
        </div>

        
        <!-- 加班/调休日期 -->
        <van-popup v-model="isDaysShow" position="bottom">
            <common-empty v-if="isEmptuyFlag"></common-empty>
            <van-picker v-else show-toolbar value-key="value" :columns="daysArr"
                @cancel="isDaysShow = false" @confirm="selectResourceBelog" />
        </van-popup>
        
        <div class="bottomBody">
            <div :class="{'part':true,'part-button':true,'part-button-enabled':checkCanSave()}" @click="save">
                保存
            </div>
        </div>
        <van-loading v-if="enable" type="spinner"  size="40px" class="upload-loading"/>

        <div class="contentFill"></div>
    </div>
</template>

<script>
    import {
        NavBar,
        DatetimePicker,
        Toast,
        RadioGroup,
        Radio,
        Picker,
        Popup,
        Checkbox, CheckboxGroup,Search,Loading
    } from 'vant'
    import {
        queryCanOverTimeMob,
        addWorkovertimecompensationMob
    } from "../../../getData/getData";
    import {
        getStaffId,
        responseUtil,
        globaluserId
    } from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    export default {
        name: 'addPotentialClient',

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Toast.name]: Toast,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Search.name]: Search,
            [Loading.name]:Loading,
            rxNavBar,
        },
        activated() {
            console.log("routeData:",this.$route.query)
            this.routeData = this.$route.query;
            if(this.routeData.type == 'work'){
                this.dayTitle = "加班"
                this.dataType = "1"
                // this.clear()
            } else {
                this.dayTitle = "调休"
                this.dataType = "2"
            }
            this.initDicData()
            this.dataForm = this.$options.data().dataForm
            this.daysArr = this.$options.data().daysArr
            this.restData = this.$options.data().restData
            this.num = 0;
        },
        mounted() {
           
        },
        data() {
            return {
                routeData: {},
                num: 0,
                dataType: '',
                restData: {
                    compensationid: '',
                    compensationTime: ''
                },
                currentDate: new Date(),
                dataForm: {
                    operDate: '',
                    expectedTime_id: '',
                    remark: '',
                },
                isDaysShow: false,
                daysArr: [],
                dayTitle:'加班',
                isEmptuyFlag: false,
                overDays: '',
                enable:false
            }
        },
        methods: {
             updateCount(val) {
                this.num = val.length;
                },
            
            save(){
                let flag = this.checkCanSave();
                if(!flag) {
                    return
                }

                if(this.enable){
                    return
                }
                this.enable = true

                var that = this
                const params = {
                    staff_id: getStaffId(),
                    expectedTime: this.dataForm.expectedTime_id,
                    dataType: this.dataType,
                    remarks: this.dataForm.remark,
                    compensationid: this.restData.compensationid,
                    compensationTime: this.restData.compensationTime
                }
                console.log(params,"params===")
                addWorkovertimecompensationMob(params).then(res => {
                    if(res.data.code == '-1'){
                        that.enable = false
                    }
                    responseUtil.dealResponse(that, res, () => {
                        responseUtil.alertMsg(that, res.data.msg)
                        that.$router.go(-1)
                        that.enable = false
                    })
                })
            },

            //初始化字典数据
            initDicData() {
                const that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.dataType = this.dataType
                queryCanOverTimeMob(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(that.dataType == '1') {
                            let arr = response.data.data.data.restDay ? response.data.data.data.restDay : []
                            if (arr.length == 0) {
                                that.isEmptuyFlag = true;
                            } else {
                                that.isEmptuyFlag = false;
                                that.daysArr = response.data.data.data.restDay
                            }
                        } else if(that.dataType == '2') {
                            let arr = response.data.data.data.overTimeDay ? response.data.data.data.overTimeDay : []
                            if (arr.length == 0) {
                                that.overDays = ''
                                that.isEmptuyFlag = true;
                            } else {
                                that.isEmptuyFlag = false;
                                that.overDays = response.data.data.data.overTimeDay
                                that.daysArr = response.data.data.data.workDay
                            }

                        }
                    })
                })

            },
            
            selectResourceBelog(item) {
                this.dataForm.expectedTime_id = item.label
                this.dataForm.expectedTime_name = item.value
                this.isDaysShow = false;
            },

            checkCanSave(){
                let that = this
                if(that.dataType == '2'){//调休
                    if(!that.dataForm.expectedTime_id) {
                        return false
                    } else {
                        return true
                    }
                } else if(that.dataType == '1'){//加班
                    if(!that.dataForm.expectedTime_id || that.num <= 0) {
                        return false
                    } else {
                        return true
                    }

                }
                
            },
            
        }
    }
</script>

<style scoped lang="less">

    .content {
        margin-top: 52px;
    }

    .part {
        margin: 15px;
        font-size: 14px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-weight: 900;
        margin-bottom: 5px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        margin-bottom: 10px;
        /*border: 1px solid red;*/
    }
    .contentFill{
        height: 70px;
    }
    .part-button {
        width: 345px;
        margin: 10px 15px;
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-hyperlinks {
        float: right;
        color: #3891fa;
        font-size: 12px;
        text-decoration: underline;
        margin-top: 10px;
    }

    .part-inputpart div:last-child {
        border: none;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-header {
        font-weight: 900;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        background-color: white;
        padding-top: 6px;
        /*padding-bottom: 8px;*/
        /*align-items: flex-end;*/
    }

    .part-inputpart-textarea textarea {
        height: 380px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-row input {
        border: 0;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-dropDownList {
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .content-divide-position {
        padding-top: 4px;
    }

    .part-inputpart-dropDownList-option {
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .partInputpartRowGraytext {
        color: #d8d8d8;
        width: 100%;
        height: 25px;
        line-height: 25px;

    }

    .partInputpartRowNormaltext {
        color: black;
        width: 100%;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    /* .part-inputpart-row-housetype input {
        width: 18px;
        margin-left: 10px;
    } */

    .part-inputpart-row-right {
        float: right;
        margin-right: 20px;
        color: rgba(199, 199, 199, 1)
    }

    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }

    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-noneslot {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #fff;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .part-inputpart-row-button {
        height: 60px;
    }

    .part-row-button {
        margin: 0;
        height: 35px;
        width: 100px;
        border-radius: 8px;
        line-height: 35px;
        font-size: 12px;
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .part-inputpart-row-short-graytext {
        color: #d8d8d8;
    }

    .radio_group {
        padding-left: 130px;
    }

    .notImport {
        padding-left: 25px;
    }

    .radio_span {
        font-size: 15px;
        color: rgba(153, 153, 153, 1);
        padding: 10px;
        padding-right: 0;

    }

    .isChecked {
        color: tomato !important;
    }

    .contentBody {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .priceInput {
        width: 82px;
        height: 31px;
        box-sizing: border-box;
        padding: 5px;
        border-radius: 5px;
        background: rgba(241, 241, 241, 1);
        color: rgba(34, 34, 34, 1);
        text-align: center;
    }
    .bottomBody{
        border-top: 1px solid #ddd;
        background: #f8f8f8;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 50;
        height:65px
    }
    //删除需求
    .deleteDemandBtnDiv{
        background-color: white;
        /*margin-bottom: 10px;*/
        height: 30px;
        line-height: 35px;
    }
    .deleteDemandBtn,.addDemandBtn{
        width: 25%;
        /*height: 35px;*/
        margin-left: 70%;
        border: none;
        color: white;
        font-size: 13px;
        border-radius: 8px;
        color: red;
        text-decoration:underline;
        /*background-image: linear-gradient(to right,#ffc274, #ff5d3b);*/
    }
    .upload-loading{
        position: fixed;
        z-index: 999;
        margin-top: 4rem;
        margin-left: 4.5rem;
    }
</style>
